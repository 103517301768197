import "./login.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Image, Input } from "antd";
import { AppDispatch } from "@redux/store";
import { loginError, loginPending } from "@redux/slice/LoginSlice";
import API from "@configs/api";
import { setUser, setUserToken } from "@redux/slice/AuthSlice";
import { getErr422 } from "@utilities/helper";
import { useDocumentTitle } from "@hooks/useDocumentTitle";
import ForgotPassword from "@pages/forgot-password";
import MESSAGES from "@configs/message";
import { HTTP_STATUS_CODE, ROUTE_PATH } from "@ts/enums";
import STORAGES_CONFIG from "@configs/storage";
import { ROLES } from "@utilities/constant";
import { notification } from "antd";
const Login = () => {
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [msgErr, setMsgErr] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useDocumentTitle("Login");

  const loginProcess = (dispatch: AppDispatch, params: any) => {
    // if (params.email === "tmed@gmail.com" && params.password === "123456aA@") {
    //   navigate("/dashboard");
    // }
    dispatch(loginPending());
    setIsLoginSuccess(true);
    API.login(params)
      .then((response: any) => {
        const res = response.data;
        if (
          res.code === HTTP_STATUS_CODE.EPHR_OK_LOGIN &&
          res.data.accessToken
        ) {
          if (res.data.userData?.role === ROLES.USER) {
            notification["error"]({
              message: "Bạn không có quyền truy cập",
            });
            throw new Error("Bạn không có quyền truy cập");
          }
          dispatch(
            setUserToken({
              roles: "ADMIN",
              userId: res.data.userData.nguoiDungId,
              token: res.data.accessToken,
            }),
          );
          dispatch(
            setUser({
              user: res.data.userData,
            }),
          );
          localStorage.setItem(STORAGES_CONFIG.token, res.data.accessToken);
          localStorage.setItem(
            STORAGES_CONFIG.userID,
            res.data.userData.nguoiDungId,
          );
          setIsLoginSuccess(true);
          setMsgErr("");
          navigate(ROUTE_PATH.dashboard);
        } else {
          setIsLoginSuccess(false);
          setMsgErr(response.data.message);
        }
        setTimeout(() => {
          setMsgErr("Request time out!");
          setIsLoginSuccess(false);
        }, 15000);
      })
      .catch((err: any) => {
        console.log("err: ", err);
        dispatch(loginError(getErr422(err)));
        setIsLoginSuccess(false);
      });
  };

  return (
    <div className="h-screen flex justify-center items-center bg-gray-800">
      {/* Login Container */}
      <div className="bg-white rounded-lg shadow-lg w-[50%] p-4 md:p-8 flex gap-4">
        {/* Logo */}

        <div className="text-center w-full flex justify-center items-center md:hidden">
          <Image
            src="/ephr-logo.png"
            alt="Logo"
            className="mx-auto mb-2"
            preview={false}
          />
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-[90%]">
            <h1 className="text-xl font-bold text-blue-600 text-center">
              Đăng nhập
            </h1>
            {/* Form */}
            <Form
              layout="vertical"
              onFinish={(values) => {
                loginProcess(dispatch, values);
              }}
            >
              <Form.Item
                name="taiKhoan"
                label="Tên đăng nhập"
                rules={[
                  { required: true, message: "Vui lòng nhập tài khoản!" },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Tên đăng nhập"
                  onChange={(e) => {
                    setMsgErr("");
                    e.target.value = e.target.value.replace(/\s/g, "");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") e.preventDefault();
                  }}
                />
              </Form.Item>

              <Form.Item
                name="matKhau"
                label="Mật khẩu"
                rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
              >
                <Input.Password
                  size="large"
                  placeholder="Mật khẩu"
                  onChange={(e) => {
                    setMsgErr("");
                    e.target.value = e.target.value.replace(/\s/g, "");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") e.preventDefault();
                  }}
                />
              </Form.Item>

              {/* Error Alert */}
              {msgErr && (
                <Alert
                  message={msgErr}
                  type="error"
                  showIcon
                  className="mb-3 py-3"
                />
              )}

              {/* Submit Button */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoginSuccess}
                  className="w-full"
                >
                  Đăng nhập
                </Button>
              </Form.Item>
            </Form>

            {/* Additional Actions */}
            <div className="text-center mt-4">
              <a href="#" className="text-blue-500 underline">
                Quên mật khẩu?
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
