import { useEffect, useState } from "react";
import {
  PoweroffOutlined,
  UnlockOutlined,
  UserOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Menu, MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
// import { showConfirmationModal } from "@redux/slice/ConfirmationRedirectSlice";
import { RootState } from "@redux/store";
import UserAvatar from "@components/UserAvatar";
import { GetCurrentUserService } from "@components/GetCurrentUser/Service";
import { LogoutService } from "@components/AppLayout/Service";
import { AppLayoutProps } from "@ts/interfaces";
import STORAGES_CONFIG from "@configs/storage";
import IMAGES from "@assets/images";
import { ROUTE_PATH, RESOURCE_DEFAULT, PERMISSION_DEFAULT } from "@ts/enums";
import { useBeforeRender } from "@hooks/useBeforeRender";
import {} from "@components/AppLayout/constant";
import { useBeAtTop } from "@hooks/useBeAtTop";
import { USER_MANUAL_GUIDE } from "@utilities/constant";

const { Header, Content, Sider } = Layout;

const AppLayout = ({ children }: AppLayoutProps) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const updateAdmin = useSelector((state: RootState) => state.updateAdmin);
  const auth = useSelector((state: RootState) => state.auth);
  const roles = auth.roles;
  const resource = roles.resources;
  const leftMenuList: MenuProps["items"] = [
    {
      key: ROUTE_PATH.dashboard,
      icon: <img src={IMAGES.dashboardIcon} alt="user" />,
      label: "Thống kê",
    },
    ...(user.role === "ADMIN"
      ? [
          {
            icon: <img src={IMAGES.companyIcon} alt="healthIcon" />,
            label: "Dịch vụ",
            key: ROUTE_PATH.health,
            children: [
              {
                key: `${ROUTE_PATH.healthCompany}`,
                label: "Dịch vụ công ty",
                icon: <HomeOutlined style={{ fontSize: "16px" }} />,
              },
              {
                key: `${ROUTE_PATH.healthHospital}`,
                label: "Dịch vụ His",
                icon: <BankOutlined style={{ fontSize: "16px" }} />,
              },
            ],
          },
        ]
      : []),
  ].filter(Boolean);

  const [menuLeft, setMenuLeft] = useState<MenuProps["items"]>([
    {
      key: ROUTE_PATH.dashboard,
      icon: <img src={IMAGES.dashboardIcon} alt="dashboardIcon" />,
      label: "Thống kê",
    },
    {
      icon: <img src={IMAGES.companyIcon} alt="healthIcon" />,
      label: "Health",
      key: ROUTE_PATH.health,
      children: [
        {
          key: `${ROUTE_PATH.healthCompany}`,
          label: "Health Company",
          icon: <HomeOutlined style={{ fontSize: "16px" }} />,
        },
        {
          key: `${ROUTE_PATH.healthHospital}`,
          label: "Health Hospital",
          icon: <BankOutlined style={{ fontSize: "16px" }} />,
        },
      ],
    },
  ]);

  const checkArrayElements = (arr1: any, arr2: any) => {
    return arr1?.some((element: any) => arr2?.includes(element));
  };
  const pushSubMenu = (path: string, menu: any) => {
    return (
      leftMenuList
        ?.find((obj: any) => obj.key === path)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ?.children?.push(menu)
    );
  };
  const resourceKeys = resource?.map((element: any) => {
    return element?.resourceKey;
  });
  const userManagementResourceArrayKeys = [
    RESOURCE_DEFAULT.USER_CMS_MANAGEMENT.key,
    RESOURCE_DEFAULT.USER_LP_MANAGEMENT.key,
  ];
  const roleManagementResourceArrayKeys = [
    RESOURCE_DEFAULT.CMS_ROLE.key,
    RESOURCE_DEFAULT.LP_ROLE.key,
  ];
  const companyResourceArrayKeys = [
    RESOURCE_DEFAULT.COMPANY.key,
    RESOURCE_DEFAULT.INDUSTRY.key,
    RESOURCE_DEFAULT.REGION.key,
    RESOURCE_DEFAULT.COUNTRY.key,
  ];
  if (checkArrayElements(resourceKeys, userManagementResourceArrayKeys)) {
    // leftMenuList?.push({
    //   key: ROUTE_PATH.users,
    //   icon: <img src={IMAGES.userManagerIcon} alt="userManagerIcon" />,
    //   label: "Users Management",
    //   children: [],
    // });
  }
  if (checkArrayElements(resourceKeys, roleManagementResourceArrayKeys)) {
    // leftMenuList?.push({
    //   key: ROUTE_PATH.roles,
    //   icon: <img src={IMAGES.roleManagerIcon} alt="roleManagerIcon" />,
    //   label: "Roles Management",
    //   children: [],
    // });
  }
  if (checkArrayElements(resourceKeys, companyResourceArrayKeys)) {
    // leftMenuList?.push({
    //   key: ROUTE_PATH.company,
    //   icon: <img src={IMAGES.companyIcon} alt="companyIcon" />,
    //   label: "Company",
    //   children: [],
    // });
  }
  if (resource) {
    resource.forEach(
      (resource: {
        resourceId: number;
        resourceName: string;
        resourceKey: string;
        permissions: any;
      }) => {
        if (resource.resourceKey === RESOURCE_DEFAULT.USER_CMS_MANAGEMENT.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.users, USER_CMS_PATH);
          //     }
          //   },
          // );
        } else if (
          resource.resourceKey === RESOURCE_DEFAULT.USER_LP_MANAGEMENT.key
        ) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.users, USER_LP_PATH);
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.CMS_ROLE.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.roles, ROLE_CMS_PATH);
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.LP_ROLE.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.roles, ROLE_LP_PATH);
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.COMPANY.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.company, COMPANY_PATH);
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.INDUSTRY.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.company, INDUSTRY_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.COUNTRY.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.company, COUNTRY_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.REGION.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       pushSubMenu(ROUTE_PATH.company, REGION_PATH);
          //     }
          //   }
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.PERSON.key) {
          resource.permissions.forEach(
            (permission: {
              permissionId: number;
              permissionName: string;
              permissionKey: string;
            }) => {
              // if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
              //   let isExist = false;
              //   leftMenuList?.forEach((record) => {
              //     if (record?.key === ROUTE_PATH.person) {
              //       isExist = true;
              //     }
              //   });
              //   if (!isExist) {
              //     leftMenuList?.push(PERSON_PATH);
              //   }
              // }
            },
          );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.ACQUISITION.key) {
          resource.permissions.forEach(
            (permission: {
              permissionId: number;
              permissionName: string;
              permissionKey: string;
            }) => {
              // if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
              //   let isExist = false;
              //   leftMenuList?.forEach((record) => {
              //     if (record?.key === ROUTE_PATH.transaction) {
              //       isExist = true;
              //     }
              //   });
              //   if (!isExist) {
              //     leftMenuList?.push(TRANSACTION_PATH);
              //   }
              // }
            },
          );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.NEWS.key) {
          resource.permissions.forEach(
            (permission: {
              permissionId: number;
              permissionName: string;
              permissionKey: string;
            }) => {
              // if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
              //   let isExist = false;
              //   leftMenuList?.forEach((record) => {
              //     if (record?.key === ROUTE_PATH.news) {
              //       isExist = true;
              //     }
              //   });
              //   if (!isExist) {
              //     leftMenuList?.push(NEWS_PATH);
              //   }
              // }
            },
          );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.INVESTOR.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       let isExist = false;
          //       leftMenuList?.forEach((record) => {
          //         if (record?.key === ROUTE_PATH.investor) {
          //           isExist = true;
          //         }
          //       });
          //       if (!isExist) {
          //         leftMenuList?.push(INVESTOR_PATH);
          //       }
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.INSIGHT.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       let isExist = false;
          //       leftMenuList?.forEach((record) => {
          //         if (record?.key === ROUTE_PATH.insight) {
          //           isExist = true;
          //         }
          //       });
          //       if (!isExist) {
          //         leftMenuList?.push(Insight_PATH);
          //       }
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.ANALYSIS.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       let isExist = false;
          //       leftMenuList?.forEach((record) => {
          //         if (record?.key === ROUTE_PATH.analysis) {
          //           isExist = true;
          //         }
          //       });
          //       if (!isExist) {
          //         leftMenuList?.push(Analysis_PATH);
          //       }
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.VIDEO.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       let isExist = false;
          //       leftMenuList?.forEach((record) => {
          //         if (record?.key === ROUTE_PATH.videoDistribution) {
          //           isExist = true;
          //         }
          //       });
          //       if (!isExist) {
          //         leftMenuList?.push(VIDEO_PATH);
          //       }
          //     }
          //   },
          // );
        } else if (resource.resourceKey === RESOURCE_DEFAULT.PAYMENT.key) {
          // resource.permissions.forEach(
          //   (permission: {
          //     permissionId: number;
          //     permissionName: string;
          //     permissionKey: string;
          //   }) => {
          //     if (permission.permissionKey === PERMISSION_DEFAULT.VIEW.key) {
          //       let isExist = false;
          //       leftMenuList?.forEach((record) => {
          //         if (record?.key === ROUTE_PATH.payment) {
          //           isExist = true;
          //         }
          //       });
          //       if (!isExist) {
          //         leftMenuList?.push(PAYMENT_PATH);
          //       }
          //     }
          //   },
          // );
        }
      },
    );
  }
  useBeAtTop();
  useBeforeRender(() => {
    setMenuLeft(leftMenuList);
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onLogout = () => {
    localStorage.clear();
    navigate(ROUTE_PATH.login);
  };

  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);

  useEffect(() => {
    getUserInfo();
  }, [updateAdmin.refresh]);

  const getUserInfo = () => {
    GetCurrentUserService.run(dispatch);
  };

  const items: MenuProps["items"] = [
    {
      key: ROUTE_PATH.profile,
      label: "Profile",
      icon: <UserOutlined />,
    },
    {
      key: ROUTE_PATH.changePassword,
      label: "Change Password",
      icon: <UnlockOutlined />,
    },
    {
      key: "",
      label: (
        <a href={USER_MANUAL_GUIDE} target="_blank" rel="noopener noreferrer">
          Help
        </a>
      ),
      icon: <InfoCircleOutlined />,
    },
    {
      key: ROUTE_PATH.logout,
      label: "Logout",
      icon: <PoweroffOutlined />,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (events) => {
    const url = window.location.pathname.split("/")[1];

    if (events.key) {
      if (url === "company-create-new") {
        // dispatch(showConfirmationModal({ targetUrl: events.key }));
      } else if (events.key === ROUTE_PATH.logout) {
        LogoutService.run(
          dispatch,
          { user: localStorage.getItem("userID") },
          onLogout,
        );
        localStorage.clear();
        navigate(ROUTE_PATH.login);
      } else {
        navigate(events.key);
      }
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    return JSON.parse(localStorage.getItem(STORAGES_CONFIG.openMenu) ?? "[]");
  });

  const onOpenChange: MenuProps["onOpenChange"] = (key) => {
    setOpenKeys(key);
    localStorage.setItem(STORAGES_CONFIG.openMenu, JSON.stringify(key));
  };

  const [selectedKeys, setSelectedKeys] = useState<string[]>(() => {
    return JSON.parse(
      localStorage.getItem(STORAGES_CONFIG.selectedKeys) ?? "[]",
    );
  });

  const handleMenuSelect = ({ key }: any) => {
    setSelectedKeys([key]);
    localStorage.setItem(STORAGES_CONFIG.selectedKeys, JSON.stringify([key]));
  };
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout className="min-vh-100 bg-gray-100">
      <Layout className="flex">
        {/* Bên trái: Logo và Menu */}
        <div className="sticky top-0">
          <Sider
            breakpoint="lg"
            collapsed={collapsed}
            collapsedWidth="0"
            className="bg-white border-r shadow-lg sticky top-0 h-screen"
            width={250}
          >
            <div className="flex items-center justify-between p-4">
              {/* Logo */}
              <div
                className="logo inline-block cursor-pointer"
                onClick={() => navigate(ROUTE_PATH.home)}
              >
                <img
                  src="/ephr-logo.png"
                  alt="EPHR"
                  className="logo"
                  style={{ height: "50px", paddingTop: "5px" }}
                />
              </div>
              {/* Icon thu gọn */}
              <div className=" ml-3">
                {collapsed ? (
                  <MenuUnfoldOutlined
                    className="text-xl"
                    onClick={() => setCollapsed(false)}
                  />
                ) : (
                  <MenuFoldOutlined
                    className="text-xl"
                    onClick={() => setCollapsed(true)}
                  />
                )}
              </div>
            </div>
            {/* Menu */}
            <Menu
              onClick={({ key }) => {
                const url = window.location.pathname.split(ROUTE_PATH.home)[1];
                if (url === "company-create-new") {
                  // dispatch(showConfirmationModal({ targetUrl: key }));
                } else navigate(key);
              }}
              mode="inline"
              selectedKeys={selectedKeys}
              onSelect={handleMenuSelect}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              className="h-full bg-white"
              items={menuLeft}
            />
          </Sider>
        </div>

        {/* Bên phải: Thanh điều hướng người dùng và Nội dung */}
        <Layout className="flex-1">
          <Header className="sticky top-0 z-50 w-full bg-white shadow-md flex justify-between items-center px-4">
            {/* Thanh điều hướng người dùng */}
            <div className="flex">
              <div className=" ml-3">
                {collapsed && (
                  <MenuUnfoldOutlined
                    className="text-xl"
                    onClick={() => setCollapsed(false)}
                  />
                )}
              </div>
              <div>Dashboard</div>
            </div>
            <div className="pe-3 inline-flex items-center">
              <label className="text-ellipsis max-w-[75ch] mr-4">
                {user?.tenNguoiDung ? user?.tenNguoiDung : ""}
              </label>
              <Dropdown menu={menuProps} placement="bottomLeft">
                <span className="cursor-pointer block">
                  <UserAvatar size={40} />
                </span>
              </Dropdown>
            </div>
          </Header>

          {/* Nội dung chính */}
          <Content className="min-h-screen bg-white shadow-md rounded-lg p-4">
            <div>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
