const ENVIRONMENT_CONFIG = {
  host: process.env.REACT_APP_HOST_API || "http://localhost:9001/ephr/v2",
  S3_URL:
    process.env.S3_URL ??
    "https://dev-doublefeather-static.s3.ap-southeast-1.amazonaws.com/",
  TIME_OUT: Number(process.env.REACT_APP_TIME_OUT) || 180000,
  LANDING_PAGE_DOMAIN_ADDRESS: process.env.REACT_APP_LP_DOMAIN_ADDRESS,
};

export default ENVIRONMENT_CONFIG;
