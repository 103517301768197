import { useDocumentTitle } from "@hooks/useDocumentTitle";
import { Row, Col, notification, DatePicker, Button, Select } from "antd";
import "./index.scss";
import { useEffect, useState } from "react";
import API from "@configs/api";
import { DEFAULT_CURRENT_MONEY } from "@utilities/constant";
import LoadingComponent from "@components/LoadingComponent";

import { convertNumberToMonth, ternaryOperatorHandle } from "@utilities/helper";
import moment from "moment";

const _ = require("lodash");
const { RangePicker } = DatePicker;
const { Option } = Select;
const Dashboard = () => {
  useDocumentTitle("Dashboard");
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalUserAuths, setTotalUserAuths] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalHistoryPriority, setTotalHistoryPriority] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleSubmit = () => {
    // Định dạng ngày theo định dạng ISO 8601
    const formattedStartDate = startDate ? startDate.toISOString() : null;
    const formattedEndDate = endDate ? endDate.toISOString() : null;
    const params = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    Promise.all([
      API.getDashboardServiceUser(params),
      API.getDashboardServiceAppointment(params),
    ])
      .then(
        ([
          totalDashboardServiceUserResponse,
          totalDashboardServiceAppointmentResponse,
        ]: any) => {
          setTotalUsers(
            totalDashboardServiceUserResponse?.data?.data?.countRegister,
          );
          setTotalUserAuths(
            totalDashboardServiceUserResponse?.data?.data?.countAuth,
          );
          setTotalAppointments(
            totalDashboardServiceAppointmentResponse?.data?.data
              ?.countAppointment,
          );
          setTotalHistoryPriority(
            totalDashboardServiceAppointmentResponse?.data?.data
              ?.countHistoryPriority,
          );
        },
      )
      .catch((error: any) => {
        notification["error"]({
          message:
            error.response?.data?.message ||
            error.response?.data?.error?.message ||
            "An error occurred",
        });
      })
      .finally(() => setLoading(false)); // Đảm bảo loading được tắt cuối cùng
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      API.getDashboardServiceUser({
        startDate,
        endDate,
      }),
      API.getDashboardServiceAppointment({
        startDate,
        endDate,
      }),
    ])
      .then(
        ([
          totalDashboardServiceUserResponse,
          totalDashboardServiceAppointmentResponse,
        ]: any) => {
          setTotalUsers(
            totalDashboardServiceUserResponse?.data?.data?.countRegister,
          );
          setTotalUserAuths(
            totalDashboardServiceUserResponse?.data?.data?.countAuth,
          );
          setTotalAppointments(
            totalDashboardServiceAppointmentResponse?.data?.data
              ?.countAppointment,
          );
          setTotalHistoryPriority(
            totalDashboardServiceAppointmentResponse?.data?.data
              ?.countHistoryPriority,
          );
        },
      )
      .catch((error: any) => {
        notification["error"]({
          message:
            error.response?.data?.message ||
            error.response?.data?.error?.message ||
            "An error occurred",
        });
      })
      .finally(() => setLoading(false)); // Đảm bảo loading được tắt cuối cùng
  }, []);

  const [selectedOption, setSelectedOption] = useState("custom");

  const handleDateChange = (dates: any) => {
    if (dates) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      setSelectedOption("custom");
    }
  };

  const handleOptionChange = (value: any) => {
    setSelectedOption(value);
    let start: moment.Moment | null = null;
    let end: moment.Moment | null = null;

    if (value === "week") {
      start = moment().startOf("week");
      end = moment().endOf("week");
    } else if (value === "month") {
      start = moment().startOf("month");
      end = moment().endOf("month");
    } else if (value === "year") {
      start = moment().startOf("year");
      end = moment().endOf("year");
    }
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div>
      <div
        className="list-user-cms-header"
        style={{ backgroundColor: "#FFFFFF", height: "120px", padding: "2%" }}
      >
        <h1 style={{ marginTop: "10px" }}>Thống kê EPHR</h1>
      </div>
      {loading ? (
        <div style={{ marginTop: "50px" }}>
          <LoadingComponent size="large" />
        </div>
      ) : (
        <>
          <div className="search-person" style={{ padding: "2%" }}>
            {/*Summarize total users and balance*/}
            <Row gutter={[6, 6]}>
              <Col span={24}>
                <div className="p-4 rounded-lg">
                  <div className="flex gap-3 items-center">
                    {/* Dropdown chọn khoảng thời gian */}
                    <Select
                      value={selectedOption}
                      onChange={handleOptionChange}
                      style={{ width: 150 }}
                    >
                      <Option value="custom">Tùy chọn</Option>
                      <Option value="week">Tuần này</Option>
                      <Option value="month">Tháng này</Option>
                      <Option value="year">Năm nay</Option>
                    </Select>

                    {/* RangePicker */}
                    <RangePicker
                      format="YYYY-MM-DD"
                      value={
                        startDate && endDate
                          ? [startDate, endDate]
                          : ([] as any)
                      }
                      onChange={handleDateChange}
                      allowClear
                      disabled={selectedOption !== "custom"}
                    />

                    {/* Nút tìm kiếm */}
                    <Button type="primary" onClick={handleSubmit}>
                      Tìm kiếm
                    </Button>
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e5ecf6",
                  }}
                >
                  <div className="dashboard-firstline-item-title">
                    Tổng số người dùng đăng ký
                  </div>
                  <div className="dashboard_firstline_item_value">
                    {totalUsers ?? 0}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e3f5ff",
                  }}
                >
                  <div className="dashboard-firstline-item-title">
                    Tổng số người dùng đã xác thực
                  </div>
                  <div className="dashboard_firstline_item_value">
                    {totalUserAuths.toLocaleString() ?? 0}{" "}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e3f5ff",
                  }}
                >
                  <div className="dashboard-firstline-item-title">
                    Tổng số đặt hẹn
                  </div>
                  <div className="dashboard_firstline_item_value">
                    {totalAppointments.toLocaleString() ?? 0}{" "}
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div
                  style={{
                    padding: "8%",
                    borderRadius: "10px",
                    backgroundColor: "#e3f5ff",
                  }}
                >
                  <div className="dashboard-firstline-item-title">
                    Tổng số người dùng quan tâm viện
                  </div>
                  <div className="dashboard_firstline_item_value">
                    {totalHistoryPriority?.toLocaleString() ?? 0}{" "}
                  </div>
                </div>
              </Col>
            </Row>
            <br />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
